$lightGray: #f2f3f7;

.custom-input {
	background-color: $lightGray;
	border-radius: 10px;
	padding: 0.9rem;
	width: 50%;
	color: #333 !important;
	font-size: 0.9rem;
	border: 0;
	outline: none !important;
	transition: all 500ms;
	box-shadow: 0px 6px 16px #dadada;

	&:focus {
		transform: scale(0.98);
	}
}
.enterprise {
	&__wrapper {
		padding: 1rem;
		@media screen and (min-width: 991px) {
			padding: 1rem 4rem;
		}
	}
	&__title {
		padding-bottom: 0.3em;
		border-bottom: 1px solid $success;
	}
	form {
		margin-top: 1.4rem;
		grid-template-rows: repeat(4, 1fr);

		& > div,
		.form > div {
			margin-bottom: 1rem;
			label {
				display: block;
				margin-bottom: 6px;
				font-size: 0.9rem;
			}
			input,
			select,
			.textarea {
				width: 100% !important;
				color: black !important;
			}
		}
		.textarea {
			resize: none !important;
			margin-bottom: 12px;
		}
		.buttons {
			gap: 1.4rem;
			margin-top: 1.5rem;
			.btn {
				border-radius: 10rem;
				color: #ffff;
			}
		}
	}
}

.photo-bg {
	background: linear-gradient(180deg, #2acc96 27.08%, #29129d 96.87%);
	box-shadow: -2px 6px 16px -1px rgba(42, 204, 150, 0.3);
	width: 110px;
	height: 110px;
	border-radius: 100%;
	padding: 0.4rem;
	position: relative;
	margin: 2rem 0;
	.photo-placeholder {
		border-radius: 100%;
	}
	.file-input {
		position: absolute;
		left: 73px;
		bottom: 10px;
		cursor: pointer;
		input {
			cursor: pointer;
			height: 30px;
			width: 54px;
			opacity: 0;
		}
		svg {
			cursor: pointer;
			pointer-events: none;
			position: absolute;
			right: 13px;
			top: 0;
		}
	}
}

.modal-content {
	width: 80%;
	margin: 0 auto;
	margin-top: 9rem;
	box-shadow: 0px 6px 16px #3333;
}
.change-password-modal {
	.form-control {
		background: #f2f3f7;
		box-shadow: 0px 6px 16px #dadada;
		border-radius: 12px;
	}
	.btn {
		box-shadow: 0px 6px 10px #dadada;
		padding: 0.5rem !important;
	}
	.btn,
	.form-control {
		transition: all 500ms;
		&:focus,
		&:hover {
			transform: scale(0.98);
		}
	}
}

.logout-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.35);
}

.company-logo-header {
	@media screen and (max-width: 280px) {
		flex-direction: column;
		justify-content: center;
		&:nth-child(1) {
			margin-bottom: 10px;
		}
	}
}
