$theme-colors: (
	"danger": #fc376b,
	"success": #2acc96,
	"blue": #29129d,
	"gray": #979797,
);

.sidebar-nav {
	padding-left: 0 !important;
}
