.transaction-table {
	border-top: 1px solid map-get($theme-colors, "success");
	border-left: 1px solid map-get($theme-colors, "success");
	border-right: 1px solid map-get($theme-colors, "success");
	thead {
		th {
			border-bottom: 1px solid map-get($theme-colors, "success");
		}
	}
	tbody {
		tr {
			border-bottom: 1px map-get($theme-colors, "gray");
		}
	}
}
