.main-layout-bg {
	background-color: #dadada;
}
.outline-none {
	outline: none;
}
.cursor-pointer {
	cursor: pointer;
}
.login-form-wrapper {
	width: 30%;
}
.sideNav_menu {
	&:hover {
		color: map-get($theme-colors, "success") !important;
	}
}
.green-gradient {
	background: linear-gradient(90deg, #00f5a0 0%, #00d9f5 100%);
}
.green-shadow {
	box-shadow: 0px 6px 16px rgba(42, 204, 150, 0.5);
}
.red-shadow {
	filter: drop-shadow(0px 6px 16px rgba(252, 55, 107, 0.5));
}
.btn {
	border: 0;
	outline: none;
	padding: 0.3rem !important;
}
.btn-success {
	background: map-get($theme-colors, "success");
}
.btn-danger {
	background: map-get($theme-colors, "danger");
}
.btn-outline-danger {
	&:hover {
		color: white !important;
		path {
			fill: white;
		}
	}
	border: 1px solid map-get($theme-colors, "danger");
}
.border-danger {
	border-color: map-get($theme-colors, "danger") !important;
}
.border-success {
	border-color: map-get($theme-colors, "success") !important;
}

.rounded-sm {
	border-radius: 8px;
}
.rounded-md {
	border-radius: 15px;
}
.rounded-full {
	border-radius: 999px !important;
}

.w-20 {
	width: 20%;
}
.w-30 {
	width: 30%;
}
.w-45 {
	width: 45%;
}

.text-sm {
	font-size: 0.8rem;
}
.text-md {
	font-size: 1rem;
}
.text-lg {
	font-size: 1.5rem;
}
.text-blue {
	color: map-get($theme-colors, "blue") !important;
}
.text-danger {
	color: map-get($theme-colors, "danger") !important;
}
.text-success {
	color: map-get($theme-colors, "success") !important;
}
.text-gray {
	color: map-get($theme-colors, "gray") !important;
}
